import React, { useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider
} from '@mui/material';
import { Form } from './useForm'
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import SectionGroup from './SectionGroupComponent';
import { BoldTextByTagName } from '../../utils/CommonFunctions';
import sanitize from 'sanitize-html';
import CloseIcon from '@mui/icons-material/Close';
import OnClickClosePopup from '../../utils/ruleEngine/AdditionPropertyRules/OnClickClosePopup';
import { Box } from '@mui/system';
import { LBL_VIEW_DOCUMENT } from '../../constants/GlobalConstants';

const useStyles = makeStyles(theme => ({
  dialogActionContainer: {
    'justify-content': 'flex-start',
    'margin-left': '14px'
  },
  title: {
    display: 'flex', 
    alignItems: 'center'
  },
  closeIcon: {
    marginRight: '14px'
  }
}))

function mapStateToProps(state) {
  return {
    popupDialogContent: state?.caseDetails?.popupSectionDetails,
    fullScreen: state?.caseDetails?.fullScreenBl
  }
}

function DialogBoxComponent(props) {

  const { open = false, section, dialogTitle, dialogContentText, maxWidth, isShowOkButton = false, isShowCancelButton = false,
    onCloseDialog, onConfirmDialog, confirmButtonText, confirmButtonColor, fullScreen } = props;
  const popupDialogContent = props?.popupDialogContent.find(e => e.sectionId == section);
  const classes = useStyles()
  const closeDocViewerControl = popupDialogContent?.sectionGroup[0].fields.find(elem => elem.mappingId === "DocViewCloseBtn")
  const isDocFilePreview = fullScreen && closeDocViewerControl && dialogTitle === LBL_VIEW_DOCUMENT;

  return (
    <>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={maxWidth || 'md'}
      >        
        <DialogTitle className={classes.title}> {isDocFilePreview && <CloseIcon onClick={() => OnClickClosePopup.execute(closeDocViewerControl, true)} className={classes.closeIcon}/>}{dialogTitle}</DialogTitle>
        <Divider />
        <DialogContent>
        <DialogContentText  dangerouslySetInnerHTML={{ __html: dialogContentText? sanitize(dialogContentText):"" }} />
{/*                    
          <DialogContentText>{BoldTextByTagName(dialogContentText)}</DialogContentText> */}
          <Form>{dialogContentBody(popupDialogContent)}</Form>
        </DialogContent>
        <DialogActions className={classes.dialogActionContainer}>
          <div>
            {isShowCancelButton &&
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    onCloseDialog()
                  }}
                >
                  Cancel
                </Button>
                &nbsp;&nbsp;&nbsp;
              </>
            }
            {isShowOkButton &&
              <Button
                variant="contained"
                color={confirmButtonColor || "primary"}
                onClick={() => {
                  onConfirmDialog()
                }}
              >
                {confirmButtonText || 'Ok'}
              </Button>
            }
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

const dialogContentBody = (popUpContent) => {
  if (popUpContent) {
    return popUpContent.sectionGroup.map(group => {
      return (
        group.isVisible &&
        <SectionGroup
          group={group}
          key={group.sectionGroupId}
          label={group.sectionGroupName}
          entitlementKey={group.description}
          isPopup={true}
        />
      )
    })
  }
  else {
    return <></>
  }
};

export default connect(mapStateToProps)(DialogBoxComponent)