import React from 'react'
import { IconProvider } from '../../providers/IconProvider'
import { DRAWER_DARK } from '../../res/values/Colors';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    iconClass: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '30px'      
    },
    icon:{
        color: "grey",
    },
    iconHover:{
        color: "grey",
        cursor: 'pointer',
        '&:hover': {
            borderRadius: '0px',
            boxShadow: 'rgba(0, 0, 0, 0.50) 2px 5px 7px'
        }
    },
    buttonLabel:{        
        fontSize:"1rem",
        minWidth:"7rem",
        paddingLeft:"10px"
    }
}));

function ImageButton(props) {
    const { color, onClick, additionalProperties } = props
    const classes = useStyles()
    var imgType = ''
    if (additionalProperties.Image) {
        imgType = additionalProperties.Image[0]
        var buttonText= additionalProperties.ImageButtonText != undefined && additionalProperties.ImageButtonText !=null?additionalProperties.ImageButtonText:null;
        var [Icon] = IconProvider(imgType)
        if (props.disabled) {
            return (
                <div className={classes.iconClass}>
                    <Icon 
                    className={classes.icon} />
               <span className={classes.buttonLabel}>{buttonText}</span> 
                </div>
            )
        }
        else {
            return (
                <div  className={classes.iconClass} onClick={onClick}>
                    <Icon  className={classes.iconHover} style={{
                        color: color || DRAWER_DARK,
                        cursor: 'pointer'
                    }} />
              <span className={classes.buttonLabel}>{buttonText}</span> 
                </div>
            )
        }
    } else {
        return <></>
    }
}

export default ImageButton
