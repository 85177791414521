//Paper
export const PAPER_BG = '#fafafc'
export const INCOMPLETE = "#f74600"

//HOME PAGE
export const DRAWER_DARK = '#17689e'
export const DRAWER_REGULAR = '#2b94c9'

//TENANT SELECT PAGE
export const TENANT_CARD_BORDER = '#2b94c9'
export const TENANT_TITLE = '#17689e'
export const TENANT_TITLE_WEIGHT = '700'
export const TENANT_BANNER_DARK = '#17689e'
export const TENANT_BANNER_REGULAR = '#2b94c9'

//HOME
//CASE NAVIGATION
export const CASE_NAVIGATION_TITLE = '#17689e'
export const CASE_NAVIGATION_BULLET_INPROGRESS = '#17689e'
export const CASE_NAVIGATION_BULLET_COMPLETE = '#179e21'
//LOGIN
export const LOGIN_TITLE = '#17689e'

//Sections
export const SECTION_HEADING_DARK = '#17689e'
export const ICON_RED = "red";
export const ICON_GR = "green";

//CAT
export const AMENDMENTS_LIST_HEADER = '#f2f2f2';
export const CAT_TABLE_HEADER = '#2b94c9';

//REPORT
export const SELECT_PAGE_TEXT_COLOR = '#0a0a0a';
