import React from 'react'
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { getViewDocDetails } from '../../reducers/CaseDetailsReducer';
import { renderPDF, renderImage, createBlobFromBase64 } from '../../utils/rendertypes';

const getFileType = (viewDocument) => {
  if (!viewDocument && !viewDocument.documentOrignalFileExtension) {
    return null;
  }

  switch (viewDocument.documentOrignalFileExtension.toString().toLowerCase()) {
    case '.pdf':
      return 'application/pdf';
    case '.png':
    case '.jpg':
    case '.jpeg':
    case '.gif':
      return 'image/png';
    default:
      return null;
  }
};

const renderViewFile = (viewDocumentObj) => {

  if (!viewDocumentObj || !viewDocumentObj.documentOrignalFileExtension) {
    return null;
  }

  switch (viewDocumentObj.documentOrignalFileExtension.toString().toLowerCase()) {
    case '.pdf':
      return renderPDF(viewDocumentObj.fileStream);
    case '.png':
    case '.jpg':
    case '.jpeg':
    case '.gif':
      return renderImage(viewDocumentObj.fileStream);
    default:     
      return <div style={{ color: 'red', 'font-size': '1rem','font-family': '"Roboto", "Helvetica", "Arial", sans-serif', 'line-height': '1.5','letter-spacing': '0.00938em', }}>
        Cannot preview files of type: "
        <span>{viewDocumentObj.documentOrignalFileExtension + '" .'}</span>
        &nbsp; Please download to view the file.

      </div>;
  }
};

const getDocumentViewerHtml = (viewDocumentObj) => {

  if (viewDocumentObj && viewDocumentObj.documentOrignalFileExtension) {
    return (
      <div style={{ minWidth: '300px', minHeight: '600px', width: '100%', height: '100%' }}>

        {renderViewFile(viewDocumentObj)}

      </div>
    );

  }
  else {
    return null;
  }
}

export default function FilePreview(props) {

  const { additionalProperties } = props
  const viewDocumentObj = useSelector(getViewDocDetails);

  return (
    <>
      {getDocumentViewerHtml(viewDocumentObj)}
    </>
  )
}
