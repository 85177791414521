import React, { Component } from 'react';
import { connect } from 'react-redux';
import { controlByType } from '../common/Controls';
import {
  updateControlValue, addFields, deleteFields, updateTableControlValue, updateClickedFieldId, getDataBySearchCriteria
} from '../../actions/CaseDetailsAction';
import { getSelectionsForControl, caseAmendmentBl, getBookingSheet, getIspopOpen, getClickedFieldId } from '../../reducers/CaseDetailsReducer';
import DialogBoxComponent from '../../components/common/DialogBoxComponent';
import ValidationEngine from '../../utils/ruleEngine/ValidationRules/ValidationEngine'
import { checkLength } from '../../utils/fieldProperties/TextBox';
import {
  BUTTON, DATE, SELECTION, TEXT, SELECTION_KEY_VALUE, ZIPCODE, NUMBERS_ONLY, TYPEAHEAD, NUMBER_ONLY, PHONE_NUMBER,
  EMAIL, CHECKBOX, PERMISSION_TYPE_ID_DISABLE_FIELD, BUTTONS, ADD_ANOTHER_FIELD_IN_TARGET_FIELD, AUTO_INCREMENT_TARGET_FIELD_VALUE,
  IMAGE_BUTTON,
  PHONE_NUMBER_ONLY,
  SELECT_FILE, BLANK_VALUE, DEFAULT_ENABLE_FIELD_LIST, pupopButton, ON_CLICK_UPDATE_TARGET_FIELD,
  ERR_INVALID_DATE
} from '../../constants/GlobalConstants';
import FormatProperty from '../../utils/ruleEngine/AdditionPropertyRules/FormatProperty';
import CustomizedFieldValue from '../../utils/CustomizedFieldValue';
import { removeTarget } from '../../actions/TypeaheadAction';
import RuleEngine from '../../utils/ruleEngine/AdditionPropertyRules/RuleEngine'
import { onChangeHandlerPhoneNumer } from '../../utils/fieldProperties/PhoneNumber';
import { getControlByFieldId } from '../../utils/GetControlByFieldId';
import { RemoveTrailingNumbersFromString } from '../../utils/fieldProperties/FormatString';
import TrayPopupComponent from './TrayPopupComponent';
import OnSelectAddValueToTargetFieldInTable from '../../utils/ruleEngine/AdditionPropertyRules/OnSelectAddValueToTargetFieldInTable';
import RuleFactory from '../../utils/ruleEngine/AdditionPropertyRules/RuleFactory';

const mapStateToProps = state => ({
  controlSelections: referenceId => getSelectionsForControl(state, referenceId),
  isCaseAmendEnable: caseAmendmentBl(state),
  bSheetData: getBookingSheet(state),
  ispopUpOpen: getIspopOpen(state),
  clickedFieldID: getClickedFieldId(state)
})

const mapDispatchToProps = dispatch => ({
  updateControlValue: (mappingId, value, parentFieldId) =>
    dispatch(updateControlValue(mappingId, value, parentFieldId)),
  updateTableControlValue: (mappingId, value, parentFieldId, index) =>
    dispatch(updateTableControlValue(mappingId, value, parentFieldId, index)),
  removeTarget: (mappingId, value) =>
    dispatch(removeTarget(mappingId, value)),
  addFields: (mappingId, fieldToIncrement) =>
    dispatch(addFields(mappingId, fieldToIncrement)),
  deleteFields: (mappingId, fieldToIncrement) =>
    dispatch(deleteFields(mappingId, fieldToIncrement)),
  updateClickedFieldId: (mappingId) =>
    dispatch(updateClickedFieldId(mappingId)),
  getDataBySearchCriteria: (rule, value) =>
    dispatch(getDataBySearchCriteria(rule, value)),
  dispatchSearchedSelectionControl: (data) =>
    dispatch(dispatchSearchedSelectionControl(data))
})

class FieldComponent extends Component {

  static propTypes = {
    //dispatchValue: PT.func.isRequired,
  };

  static defaultProps = {
    isLocked: false,
    updateControlProps: () => null,
  };

  state = {

    validationResponse: null,
    isFocusHandled: false

  };

  /**
   * Fetch the appropriate control component
   */
  constructor(props) {
    super(props);
    this.getControl(
      props.control
    );
    this.timeout = 0;
  }

  //will fire on page load and control onchange events and work based on parameters
  initEngines = (control, isOnChange, flag = true) => {

    if (control.isVisible) {
      const additionProperty = control.additionalProperties
      if (additionProperty && flag) {
        RuleEngine.initializeEngine(control, isOnChange)
      }

      const response = ValidationEngine.initializeValidationEngine(control, isOnChange);
      this.setState({ validationResponse: response });
      this.props.validateSectionControls(control);

    }
  }

  //execute rule and control validation on page load
  componentDidMount() {
    const control = {...this.props.control}
    if(control.value === ERR_INVALID_DATE) {
      control.value = null
    }
    this.initEngines(control, false)
  }

  //get appropriate HOC control based on their type
  getControl(control) {
    let controlValue = controlByType(control.fieldTypeName);
    if (controlValue != undefined) {
      let [Control, overrides] =
        controlValue;
      this.Control = Control;
      this.overrides = overrides;
    }
  }

  /**
    * Updates a controls value, passed via props to delegate Control components
    * @param {any} value Dispatch an action to update the field value in the store
    */
  dispatchValue = (e, values, fireEffects = true) => {
    //this.props.updateControlValue( this.props.control.fieldId, this.props.control.value );
    var parentFieldId = this.props.control.parentFieldId
    var index = this.props.index;

    if (this.props.control.fieldTypeName === DATE) {
      this.props.updateControlValue(this.props.control.mappingId, e, parentFieldId);
    }
    else if (this.props.control.fieldTypeName === SELECTION || this.props.control.fieldTypeName === SELECTION_KEY_VALUE || this.props.control.fieldTypeName === TYPEAHEAD) {
      this.props.updateControlValue(this.props.control.mappingId, values, parentFieldId)
    }
    else if (this.props.control.fieldTypeName === TEXT) {
      var fieldValue = e.target.value.trimStart();
      const checkFieldFormat = this.props.control.additionalProperties.Format;
      if (fieldValue == null || fieldValue == "") {
        this.props.updateControlValue(this.props.control.mappingId, fieldValue, parentFieldId)
      } else if (checkFieldFormat && (checkFieldFormat == NUMBERS_ONLY)) {
        var numbers = NUMBER_ONLY;
        if (e.target.value.match(numbers)) {
          this.props.updateControlValue(this.props.control.mappingId, fieldValue, parentFieldId)
        }

      } else if (checkFieldFormat && checkFieldFormat == PHONE_NUMBER_ONLY) {
        this.props.updateControlValue(this.props.control.mappingId, onChangeHandlerPhoneNumer(fieldValue), parentFieldId)
      } else {
        this.props.updateControlValue(this.props.control.mappingId, fieldValue, parentFieldId)
      }
    }
    else if (this.props.control.fieldTypeName === CHECKBOX) {
      var fieldValue = e.target.checked
      if (index != null && index != undefined) {
        this.props.updateTableControlValue(this.props.control.mappingId, fieldValue ? '1' : '0', parentFieldId, index)
      }
      else {
        this.props.updateControlValue(this.props.control.mappingId, fieldValue ? '1' : '0', parentFieldId)
      }
    }
    else if (this.props.control.fieldTypeName === PHONE_NUMBER) {
      var isFormatValid = FormatProperty.execute(this.props.control, e.target.value);
      if (isFormatValid) {
        this.props.updateControlValue(this.props.control.mappingId, e.target.value, parentFieldId)
      }
    }
    else if (this.props.control.fieldTypeName === SELECT_FILE) {
      this.props.updateControlValue(this.props.control.mappingId, e.target.files[0])
    }
    else {
      if (e != null && e.target.value != undefined) {
        this.props.updateControlValue(this.props.control.mappingId, e.target.value, parentFieldId)
      }
    }

    this.initEngines(this.props.control, true)
  };

  onDelete = (id) => {
    this.props.removeTarget(this.props.control.mappingId, id)
    this.initEngines(this.props.control, true)
  }

  onClickHandler = (e, values, fireEffects = true) => {

    //This block will be executed for OnClickUpdateTargetField rule
    if (this.props.control.additionalProperties && this.props.control.additionalProperties.OnClickUpdateTargetField) {
      var rule = RuleFactory.getApplicableRule(ON_CLICK_UPDATE_TARGET_FIELD)
      if (rule) {
          rule.execute(this.props.control, true)
      }
    }

    if (this.props.control.additionalProperties && this.props.control.additionalProperties.OnAddExecuteRules) {
      var rulesTOExecute = this.props.control.additionalProperties.OnAddExecuteRules;
      var fieldToIncrement = null;
      var targetField = null;
      rulesTOExecute.forEach(element => {
        var keys = Object.keys(element)
        var values = Object.values(element)
        for (var item = 0; item < keys.length; item++) {
          if (keys[item] == ADD_ANOTHER_FIELD_IN_TARGET_FIELD) {
            targetField = values[item].TargetFieldId;
          }
          else if (keys[item] == AUTO_INCREMENT_TARGET_FIELD_VALUE) {
            values[item].forEach(value => {
              fieldToIncrement = value.TargetFieldId;
            })
          }
        }
      });
      if (targetField) {
        this.props.addFields(targetField, fieldToIncrement)
      }
    }
    else if (this.props.control.additionalProperties && this.props.control.additionalProperties.OnDeleteExecuteRules && this.props.index) {
      this.props.deleteFields(this.props.control, this.props.index)
    }
    this.initEngines(this.props.control, true)
    
    if (!pupopButton.includes(this.props.control.mappingId)) {
      this.props.updateClickedFieldId(this.props.control.mappingId);
    }
  }

  onInputChange = (e, value, fireEffects = true) => {
    const fieldProps = this.props.control.additionalProperties;
    let rule = fieldProps.GetReferenceDataBySearchCriteria;

    if (rule) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.props.getDataBySearchCriteria(rule, value);
      }, process.env.REACT_APP_AutoComplete_Delay);
    }
  }

  onSelect = (e, value, fireEffects = true) => {
    OnSelectAddValueToTargetFieldInTable.execute(this.props.control, value, fireEffects = true);
    this.initEngines(this.props.control, true);
  }
  
  getSelections = (referenceId) => {
    var selections = this.props.controlSelections(referenceId);
    if (selections == undefined || selections == null) {
      if (this.props.control.parentMappingId) {
        selections = this.props.controlSelections(this.props.control.parentMappingId);
      }
    }
    return selections;
  }

  getFieldMaxLength = (Control) => {
    if (this.props.control.fieldTypeName === TEXT || this.props.control.fieldTypeName === ZIPCODE) {
      var allowedMaxLength = checkLength(Control);
      return allowedMaxLength;
    }
  }

  renderDialog() {
    if (this.props.clickedFieldID == this.props.control.mappingId) {
      var isTraypopup = false;
      if (this.props.control.additionalProperties.OnClickShowPopup != undefined) {
        var popupInformation = this.props.control.additionalProperties.OnClickShowPopup ? this.props.control.additionalProperties.OnClickShowPopup : null;
        var dialogContentText = popupInformation?.PopupBriefDescription ? popupInformation?.PopupBriefDescription?.DialogMessageId?.DefaultText : null;
        var dialogTitle = popupInformation?.PopupTitle ? popupInformation?.PopupTitle?.DialogMessage?.DefaultText : null;
        var sectionId = popupInformation?.SectionId
        var maxWidth = 'lg';
        isTraypopup = true;
      }
      else if (this.props.control.additionalProperties.OnClickShowSectionAsPopupWithDependentFields == undefined) {
        var popupInformation = this.props.control.additionalProperties.OnClickShowSectionAsPopup ? this.props.control.additionalProperties.OnClickShowSectionAsPopup : null;
        var dialogContentText = popupInformation?.PopupBriefDescription ? popupInformation?.PopupBriefDescription?.DialogMessageId?.DefaultText : null;
        var dialogTitle = popupInformation?.PopupTitle ? popupInformation?.PopupTitle?.DialogMessage?.DefaultText : null;
        var sectionId = popupInformation?.SectionId
      }
      else if (this.props.control.additionalProperties.OnClickShowSectionAsPopupWithDependentFields != undefined) {
        var popupInformation = this.props.control.additionalProperties.OnClickShowSectionAsPopupWithDependentFields ? this.props.control.additionalProperties.OnClickShowSectionAsPopupWithDependentFields : null;
        var dialogContentText = popupInformation?.PopupBriefDescription ? popupInformation?.PopupBriefDescription?.DialogMessageId?.DefaultText : null;
        var dialogTitle = popupInformation?.PopupTitle ? popupInformation?.PopupTitle?.DialogMessage?.DefaultText : null;
        var sectionId = popupInformation?.SectionId
        var maxWidth = popupInformation?.MaxWidth;
      }
      var getPopUpOpenState = this.props.ispopUpOpen;
      if (isTraypopup) {
        return <TrayPopupComponent
          open={getPopUpOpenState}
          dialogTitle={dialogTitle}
          section={sectionId}
          parentComponent={this.props.control.mappingId}
          dialogContentText={dialogContentText}
          maxWidth={maxWidth}
        />
      } else {
        return <DialogBoxComponent
          open={getPopUpOpenState}
          dialogTitle={dialogTitle}
          section={sectionId}
          parentComponent={this.props.control.mappingId}
          dialogContentText={dialogContentText}
          maxWidth={maxWidth}
        />
      }
    }
  }


  render() {
    let {
      fieldId,
      defaultFieldName,
      value,
      requiredBl,
      additionalProperties,
      mappingId,
      permissionTypeId,
      showDialog,
      ...otherProps
    } = this.props.control;
    if (showDialog == undefined || showDialog == null) {
      showDialog = getControlByFieldId(fieldId)?.showDialog;
    }
    var disabled = permissionTypeId == PERMISSION_TYPE_ID_DISABLE_FIELD ? true : false
    const { Control, overrides } = this;
    var helperText = this.state.validationResponse ? this.state.validationResponse.message : '';
    var validateIsRequired = this.state.validationResponse ? this.state.validationResponse.isRequired : null;
    if (this.props.control.overrideValidationFlag) {
      helperText = BLANK_VALUE,
        validateIsRequired = false
    }
    if (disabled) {
      helperText = BLANK_VALUE;
    }
    var hasValue = (value && value !== ERR_INVALID_DATE) ? value : false;
    if (additionalProperties.GetReferenceDataBySearchCriteria != undefined) {
      var selections = this.getSelections(additionalProperties.GetReferenceDataBySearchCriteria.ReferenceDataName)
    }
    else if (additionalProperties.LoadReferenceDataByFilterInTables != undefined) {
      let tableRowIndex = mappingId.replace(/^\D+/g, "");
      let rule = additionalProperties?.LoadReferenceDataByFilterInTables;
      let loadRreferenceDataId = rule.LoadReferenceData.replaceAll("[n]", tableRowIndex);
      var selections = this.getSelections(loadRreferenceDataId);
    }
    else {
      var selections = this.getSelections(additionalProperties.LoadReferenceData)
    }
    var isHelperTexterror = helperText ? true : false

    // To remove the Index from string
    let mappingIdWithoutNumber = RemoveTrailingNumbersFromString(mappingId);
    const isFieldFound = DEFAULT_ENABLE_FIELD_LIST.some(element => { if (element === mappingIdWithoutNumber) return true; });
    let checkFieldStatus = disabled || (this.props.bSheetData != null && !this.props.isCaseAmendEnable);
    var isDisabled = isFieldFound ? false : checkFieldStatus;

    // To set focus on first element on edit
    if (!this.state.isFocusHandled && !isDisabled && otherProps.displayOrder == 1 && this.props.groupDisplayOrder == 1) {
      this.setState({
        isFocusHandled: true
      })
      setTimeout(() => {
        try {
          document.getElementById(mappingId).focus()
        } catch {
        }
      }, 100);
    }
    return (
      <>
        {Control &&
          <Control
            fieldId={mappingId}
            selections={selections}
            maxLength={this.getFieldMaxLength(this.props.control)}
            value={value}
            label={defaultFieldName}
            additionalProperties={additionalProperties}
            isRequired={requiredBl || validateIsRequired}
            {...overrides}
            {...otherProps}
            index={this.props.index}
            disabled={isDisabled}
            error={((requiredBl) && !hasValue) || isHelperTexterror}
            helperText={helperText ? helperText.toString() : ''}
            onChange={(e, values) => { this.dispatchValue(e, values) }}
            onClick={otherProps.fieldTypeName == BUTTON || BUTTONS || IMAGE_BUTTON ? (e, values) => { this.onClickHandler(e, values) } : null}
            onDelete={this.onDelete}
            transformer={CustomizedFieldValue.execute}
            validateSectionControls={(control) => this.props.validateSectionControls(control)}
            onInputChange={(e, values) => { this.onInputChange(e, values) }}
            onSelect={(e, values) => { this.onSelect(e, values) }}
            onRemove={(e, values) => { this.onRemove(e, values) }}

          />

        }
        {showDialog ? this.renderDialog() : null}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FieldComponent);