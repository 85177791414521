import makeStyles from '@mui/styles/makeStyles';
import Controls from "./Controls"

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    }
}))

export default File = props => {
    const classes = useStyles()
    const { fieldId, name, label, value, error, isRequired, disabled, helperText, onChange, isShowFilePreview = true } = props
    var fileUri
    if (value) {
        fileUri = value && typeof(value) == "object" ? URL.createObjectURL(value) : value
    }
    return (
        <>
            <Controls.Typography>
                {label}
            </Controls.Typography>
            <input
                id={fieldId}
                name={name}
                className={classes.root}
                type='file'
                accept="*"
                disabled={disabled}
                onChange={onChange}
            />
            {isShowFilePreview &&
                <>
                    <br />
                    <br />
                    {/* Preview Code */}
                    {
                        value ? value.type.includes('image') ? <img src={fileUri} width={200} alt="Preview" /> : value.type.includes('pdf') ? <embed className="embed-content" style={{ width: '100%', height: '85%' }} type="application/pdf" src={fileUri} /> : <Controls.Typography>No Preview</Controls.Typography> : null
                    }
                </>
            }

        </>


    )
}