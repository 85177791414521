import { CloudDownload, Delete, GetApp, Visibility, Cancel, CheckCircle,AddBox } from "@mui/icons-material";

export const IconProvider = iconType => {
    const type = iconType.toLowerCase().trim();
    const icon = {
        delete : [Delete],
        downloaddoc: [CloudDownload],
        viewdoc: [Visibility],
        successtickmark: [CheckCircle],
        failedtickmark:[Cancel],
        plusbutton:[AddBox]
    }
    return icon[type] || undefined;
 }
